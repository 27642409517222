import { useState, useEffect } from 'react';
import { getMaxWidth, ITEM_WIDTH } from '../utils/featureSelectorUtils';

const getContainerWidth = (viewportWidth: number, itemCount: number): number => {
  const maxWidth = getMaxWidth(viewportWidth);

  const totalItemWidth = ITEM_WIDTH * itemCount + 10; // 10 = InnerSelectorContainer padding
  return Math.min(totalItemWidth, maxWidth);
};

export const useContainerWidth = (
  colorSelectorOpen: boolean,
  colorsLength: number,
  rimsLength: number,
) => {
  const [containerWidth, setContainerWidth] = useState(() =>
    getContainerWidth(window.innerWidth, colorsLength),
  );

  useEffect(() => {
    const updateWidth = () => {
      const itemCount = colorSelectorOpen ? colorsLength : rimsLength;
      setContainerWidth(getContainerWidth(window.innerWidth, itemCount));
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [colorSelectorOpen, colorsLength, rimsLength]);

  return containerWidth;
};
